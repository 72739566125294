import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Base from '../components/base';
import SEO from '../components/seo';

const CyberSecurityImages = ({ location }: PageProps) => {
  return (
    <Base location={location}>
      <div className="text-black relative mt-[5.625rem] lg:mt-0">
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingTop: '56.2225%',
            paddingBottom: 0,
            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
            marginBottom: '1.6em',
            overflow: 'hidden',
            borderRadius: '8px',
            willChange: 'transform',
          }}
        >
          <iframe
            loading="lazy"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0,
            }}
            src="https://www.canva.com/design/DAGbZ7uA4ns/4cbVBe4ZvQ-AwLIw2rbqgg/view?embed"
            title=" "
            allowFullScreen
            allow="fullscreen"
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <StaticImage src="../images/mark1.jpg" alt="Image 1" />
          <StaticImage src="../images/mark2.jpg" alt="Image 2" />
          <StaticImage src="../images/mark3.jpg" alt="Image 3" />
          <StaticImage src="../images/mark4.jpg" alt="Image 4" />
          <StaticImage src="../images/mark5.jpg" alt="Image 5" />
        </div>
      </div>
    </Base>
  );
};

export default CyberSecurityImages;

export const pageQuery = graphql`
  query CyberSecurityImages($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO />;
